
import backVideo from './backVideo.mp4'
import insta from './Instagram_50px.png';
import linkedin from './linkedin_50px.png';
import twitter from './twitter_50px.png';
import Youtube from './YouTube_50px.png';
import Bombarn  from './bombarn.JPG';
import Dodde  from './dodde.jpg';
import logo from './logoSwan.png';
import parkSketch from './Park Kristianstad.png';
import '@fontsource/roboto/400.css';
import './App.css';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Slide  from '@mui/material/Slide';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="Nav"> <img src={logo} alt="LOGo" id="JELogo"></img> </div>

        <div id="Body"> 
          <div id="videoWrapper">
            <video id="backVideo" playsInline autoPlay muted loop>
              <source src={backVideo} type="video/mp4" /> 
            </video>  
          </div>


        </div>


        <div id="secondPage"> 

          <div id="textContentSecondPage">
            <Typography variant="h6" component="h2">Jansson Enterprizes AB </Typography>
            <br></br>
            <br></br>


            <Typography variant="h4" component="h2">Vi hjälper dig på vägen mot</Typography>
            <Typography variant="h2" component="h2">mästerlighet</Typography>
            <br></br>
            <br></br>
            
            
            <Typography variant="body1" component="h2" id="bodyText">Jansson Enterprizes är medlem i det slutna sällskapet 5am club och har Jansson har med sin otroliga mästerlighet och oändliga källa av visdom och kunskap hjälp företag och privatpersoner att nå sin innersta förmåga och mästerlighet </Typography>
          </div>
        <Slide direction="left" in={window.scrollY=2} mountOnEnter unmountOnExit>
        <div id="testemonialWrapper">  
          <div id="testemonial">            
          <Typography variant="body1" component="h2" id="testemonialText">"En gång i tiden när jag var 18 år, luspank och hemlös, flyttade jag in hos min flickväns mamma i ett miljonprogram. Jag kontaktade Jansson Enterprizes som hjälpte mig förstå Kellys Kriterion och vad jag visste var att nu kommer hon inte behöva ta hand om mig längre, nu kan jag ta hand om henne."</Typography>
              <div id="fullAvatar">
              <Avatar
              alt="Remy Sharp"
              src={Bombarn}
              sx={{ width: 70, height: 70 ,zIndex: 1}}/>
              <div id="AvatarText">
              <Typography variant="h7" component="h2" id="bodyText">Bombarn</Typography>
              <Typography variant="body2" component="h2" id="bodyText">Legend</Typography>
            </div></div>
          </div>
          </div>
        </Slide>


        </div>

        <div id="thirdPage">
        <Typography variant="h4"  color="gray" id="ThirdPageHeader">Appleverantör av Kristianstad kommuns parkeringsapp</Typography>
        <img src={parkSketch} id="ParkSketch" alt="Kkk"></img>
        </div>

        
        <div id="fourthPage">

        <Typography variant="h4"  color="gray" id="ThirdPageHeader">Vill du beställa stickers?</Typography>
        
        
        <div id="testemonialWrapper2">  
          <div id="testemonial2">            
          <Typography variant="body1" component="h2" id="testemonialText">"Vi på Jansson Enterprizes har efter riktade marknadskampanjer sett en ökad efterfrågan efter stickers och kommer inom kort lansera möjligheten att beställa stickers via vår hemsida! Håll utkik här och följ oss på sociala medier för att inte missa denna unika möjlighet!"</Typography>
              <div id="fullAvatar">
              <Avatar
              alt="Remy Sharp"
              src={Dodde}
              sx={{ width: 70, height: 70 ,zIndex: 1}}/>
              <div id="AvatarText">
              <Typography variant="h7" component="h2" id="bodyText">Patric Johansson</Typography>
              <Typography variant="body2" component="h2" id="bodyText">Head of Sales</Typography>
            </div></div>
          </div>
          </div>

        
        
        
        </div>


        <div id="footer"> 
          <div id="left">
            <img src={logo} alt="LOGo" id="JELogoFooter"></img> 
          </div>

          <div id="mid"><p id="midText"> Jansson Enterprizes AB är ett svenskt börsnoterat bolag på OMX30. Varumärket Jansson Enterprizes är skyddat enligt konstens alla regler i 193 av världens länder och vid all kränkning av detta varumärketskydd kommer åtgärder att vidtas. <br></br><br></br>© Jansson Enterprizes AB, 1998-2022</p></div>

          <div id="right"> 
            <a href="https://www.instagram.com/janssonenterprizes/">
              <img src={insta} id="social" alt="Insta"></img> 
            </a>
            <img src={twitter} id="social" alt="twitter"></img>
            <img src={Youtube} id="social" alt="Youtube"></img>
            <img src={linkedin} id="social" alt="linkedin"></img>
          </div>


        </div>


      </header>
    </div>
  );
}

export default App;
